const { renderEventsCarouselItem } = require("./events");
const { renderGalleryImages } = require("./gallery");
const { currentPage, elementVisibility, setSessionStorage, getSessionStorage, renderCountryCode, formatDateAndTimeString } = require("./module");
const { renderProjectCarouselItem } = require("./project");
let itemDataArray, totalPages, itemReverseData;
let itemsPerPage = 12;
let currentPaginationPage = 1;
let currentContentIndex = 0;

let tablesResult;

const tableObject = {
	table_array: [
		{
			table: 'events_items',
			total_rows: 20
		},
		{
			table: 'news_items',
			total_rows: 20
		},
		{
			table: 'project_items',
			total_rows: 10
		},
		{
			table: 'gallery_items',
			total_rows: 60
		},
		{
			table: 'hope_connect_items',
			total_rows: 20
		},
	]
}

document.addEventListener('DOMContentLoaded', async function() {
	AOS.init({
		duration: 800,
		easing: 'slide'
	});
	
    // Get a reference to the button
    var scrollToTopBtn = document.getElementById('scrollToTopBtn');

    // Add a click event listener on the button
    scrollToTopBtn.addEventListener('click', function() {
        // Scroll to the top of the document
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll animation
        });
    });

    // Show the button when scrolling down
    window.addEventListener('scroll', function() {
        // Show button when we scroll down 20px from the top
        if (window.scrollY > 20) {
            scrollToTopBtn.style.display = 'block';
        } else {
            scrollToTopBtn.style.display = 'none';
        }
    });

	// loader
	var loader = function() {
		setTimeout(function() { 
		   if($('#ftco-loader').length > 0) {
			   $('#ftco-loader').removeClass('show');
		   }
		}, 1);
	};
	loader();

	const resultSessionObject = getSessionStorage("dc02a18f-e4df-485d-b4ac-2b527ef8dbb7", 10);
	if (resultSessionObject) {
	    console.log("Content load from session storage:", resultSessionObject);
		renderTableResult(resultSessionObject);
	} else {
		const response = await fetch('https://host.hopehomecalcutta.org/php/content_data.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(tableObject)
		});
	
		const data = await response.json();
	
		if (data.success) {
			const result = data.results;
			console.log("Data fetched from server:",result);
			setSessionStorage("dc02a18f-e4df-485d-b4ac-2b527ef8dbb7", result);
			renderTableResult(result);
		} else {
			console.error(data.message);
			alert(data.message);
		}
	}

	renderCountryCode();

	const footerBottomLabel = document.querySelector(".footer-bottom-label");
	const currentYear = new Date().getFullYear();
	footerBottomLabel.innerHTML = `
	Copyright ©${currentYear} | All rights reserved by Hope Home Calcutta | Designed by <a href="https://mountoliveacademy.org" target="_blank" rel="noopener noreferrer">Mount Olive Academy</a>
	`;
});

document.addEventListener('DOMContentLoaded', function() {
  const toggler = document.querySelector('.navbar-toggler');
  const navCollapse = document.getElementById('ftco-nav');

  toggler.addEventListener('click', function() {
    if (navCollapse.classList.contains('show')) {
      	navCollapse.classList.remove('show');
	  	toggler.innerHTML = `<i class='bx bx-menu' ></i>`;
    } else {
      	navCollapse.classList.add('show');
	  	toggler.innerHTML = `<i class='bx bx-x'></i>`;
    }
  });
});



function renderTableResult(result){
	tablesResult = result;
	// Render data
	if (currentPage === "" || currentPage === "index.html" || currentPage === "project.html"){
		const projectArray = getDataByTable(result, 3, 5);
		
		if(projectArray.length > 0){
			renderProjectCarouselItem(projectArray);
		}
	} else if (currentPage === "event-and-news.html"){
		const eventArray = getDataByTable(result, 1, 5);
		if(eventArray.length > 0){
			renderEventsCarouselItem(eventArray);
		}

		const newsArray = getDataByTable(result, 2, 10);
		if(newsArray.length > 0){
			loadContentsElements(1, newsArray);
		}

	} else if (currentPage === "gallery.html"){
		const galleryArray = getDataByTable(tablesResult, 4);
		if(galleryArray.length > 0){
			renderGalleryImages(galleryArray);
		}

	} if(currentPage === "hope-connect.html"){
		const connectArray = getDataByTable(result, 5, 12);
		if(connectArray.length > 0){
			loadContentsElements(2, connectArray);
		}

	}  else {
		currentContentIndex = 0;
	}
}

// Function to get data matching the table property and return last maxRows by id
function getDataByTable(resultArray, tableState, maxRows) {
	var tableName;
	if(tableState === 2){
		tableName = tableObject.table_array[1].table;
	} else if(tableState === 3){
		tableName = tableObject.table_array[2].table;
	} else if(tableState === 4){
		tableName = tableObject.table_array[3].table;
	} else if(tableState === 5){
		tableName = tableObject.table_array[4].table;
	} else {
		tableName = tableObject.table_array[0].table;
	}

	if(tableName){
		// Filter the array by tableName
		const filteredArray = resultArray.filter(item => item.table === tableName);
		const filteredArrayData = filteredArray[0].data;

		// Sort the array by date in descending order
		const sortedArray = filteredArrayData.sort((a, b) => {
		    const dateA = new Date(a.date);
		    const dateB = new Date(b.date);
		
		    if (isNaN(dateA.getTime())) return 1; // Treat invalid dates as later
		    if (isNaN(dateB.getTime())) return -1; // Treat invalid dates as later
		
		    return dateB - dateA; // Sort in descending order
		});

		console.log(sortedArray);
		
		// Check if maxRows is provided and greater than 0
		if (maxRows && maxRows > 0) {
		    // Return the first maxRows entries
    		const sliceArray = sortedArray.slice(0, maxRows);
			console.log("sliceArray:", sliceArray);
			return sliceArray.reverse();
		} else {
		    // Return all rows if maxRows is not provided or less than or equal to 0
		    return sortedArray.reverse();
		}


	}
}



function loadContentsElements(index, data){
	itemReverseData = data.reverse();
	totalPages = Math.ceil(itemReverseData.length / itemsPerPage);
    renderItems(index, currentPaginationPage);
    createPagination();
}

// Function to render items for the current page
function renderItems(index, page) {
	console.log("renderItems //// Page :", page, "\ndata:", itemReverseData);
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const itemsToRender = itemReverseData.slice(start, end);

    const container = document.querySelector('.append-parent-container');
    container.innerHTML = '';
    itemsToRender.forEach(item => {
		const response = createRenderItems(index, item);
      	container.innerHTML += response;
    });

	if(index === 1){
		elementVisibility("#news", 1);
	} else if(index === 2){
		elementVisibility("#hope-connect", 1);
	}
}

// Function to create connect-item HTML
function createRenderItems(index, data) {
	if (index === 1){
		const dateStr = formatDateAndTimeString(data.date);
		// News items
		return `<div data-id="${data.id}" class="news-items col-md-4 d-flex">
          	<div class="blog-entry align-self-stretch">
              	<a href="${data.imgURL}" class="block-20" style="background-image: url('${data.imgURL}');" target="_blank"></a>
              	<div class="text p-4 d-block">
                	<div><span class="icon-calendar mr-1"></span> ${dateStr}</div>
                	<h3 class="heading mt-2"><a href="${data.fileURL !== "0" ? data.fileURL : '#'}" target="_blank">${data.title}</a></h3>
                	<p>${data.description}</p>
              	</div>
            </div>
        </div>`;
	} else 	if(index === 2){
		// connect items
		return `<div data-id="${data.id}" class="connect-items col-md-6 d-flex">
		  	<div class="blog-entry align-self-stretch">
				<a href="${data.imgURL}" class="block-20" style="background-image: url('${data.imgURL}');" target="_blank"></a>
				<div class="text p-4 d-block">
				  <h3 class="heading"><a href="${data.fileURL}" target="_blank">${data.title}</a></h3>
				  <p>${data.description}</p>
				  <p><a href="${data.fileURL}" target="_blank">Know more <i class="ion-ios-arrow-forward ml-2"></i></a></p>
				</div>
		  	</div>
		</div>
	  	`;
	} 

}



// Function to create pagination HTML
function createPagination() {
	if(currentPaginationPage > 1){
		const paginationContainer = document.querySelector('.pagination-container');
		let paginationHtml = '<ul>';
	
		paginationHtml += `<li class="previous-pagination"><a href="#" onclick="changePage(${currentPaginationPage - 1})">&lt;</a></li>`;
		for (let i = 1; i <= totalPages; i++) {
		  paginationHtml += `<li class="pagination-item ${i === currentPaginationPage ? 'active' : ''}"><a href="#" onclick="changePage(${i})">${i}</a></li>`;
		}
		paginationHtml += `<li class="next-pagination"><a href="#" onclick="changePage(${currentPaginationPage + 1})">&gt;</a></li>`;
	
		paginationHtml += '</ul>';
		paginationContainer.innerHTML = paginationHtml;
	}
}

// Function to change the current page
window.changePage = function(page){
    if (page < 1 || page > totalPages) return;
    currentPaginationPage = page;
    renderItems(currentContentIndex, currentPaginationPage);
    createPagination();
}
